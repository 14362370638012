/* eslint-disable vue/no-unused-components */
<template>
  <div :class="{ 'container-fluid': !hideHeader || noPadding }">
    <EnquetePosteProgrammeFormDialog
      ref="enqueteDialog"
      :title="'Edition d\'enquête poste programme'"
      @saved="handleEnqueteSave"
    />

    <!-- <EnquetePosteProgrammeClotureDialog
      ref="cloturerEnqueteDialog"
      :title="'Clôturer d\'enquête poste programme'"
      @saved="handleClôturerEnqueteSave;"
    /> -->
    <div class="container- mt-4">
      <template v-if="!hideHeader">
        <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
        <div class="bg-white p-2 rounded-4">
          <form class="bg-" method="post" accept-charset="utf-8" action="">
            <div class="row py-0 p-fluid grid formgrid">
              <div class="col field">
                <form class="d-flex" @submit.prevent="submitQuery">
                  <div class="input-group me-4">
                    <div class="p-inputgroup">
                      <PInputText
                        type="text"
                        v-model="mxSearch"
                        :placeholder="'Rechercher par'"
                      />
                      <!-- <p-multi-select v-model="mxSearchFields" :options="searchFieldsOptions.filter(el => el.visible)" optionLabel="label" optionValue="value" :editable="true"/> -->
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-6 col-sm-12 d-flex">
                <div class="input-group me-4">
                  <div class="p-inputgroup"></div>
                </div>
              </div>
              <div class="col-md-auto col-sm-12 d-flex">
                <div class="d-grid gap-2">
                  <a
                    href="#"
                    @click.prevent="submitQuery"
                    class="style_btn btn btn-primary"
                    id="button-query"
                    ><i class="bi bi-search"></i
                  ></a>
                </div>
              </div>
              <div class="col-auto">
                <button
                  type="button"
                  @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                  class="style_btn btn btn-warning"
                  id="button-query"
                >
                  <i class="bi bi-filter"></i>
                </button>
              </div>
            </div>
          </form>
          <transition>
            <div class="card mx- border-radius-5 mt-2 hadow-5" v-if="showAdvancedFilter">
              <div class="row p-fluid p-3 grid">
                <div class="field col-12 col-md-3">
                  <label for="basic">Date de début</label>
                  <p-calendar
                    placeholder="Date de début"
                    v-model="dateDeb"
                    locale="fr"
                    :showIcon="true"
                    :manualInput="false"
                  />
                </div>
                <div class="field col-12 col-md-3">
                  <label for="basic">Date de fin</label>
                  <p-calendar
                    placeholder="Date de fin"
                    class="flex-grow-1"
                    v-model="dateFin"
                    :showIcon="true"
                    :manualInput="false"
                  />
                </div>
                <template v-for="(option, index) in filterOptions">
                  <div class="col-12 col-md-3 mb-4" v-if="option.visible" :key="index">
                    <label for="basic">{{ option.label }}</label>
                    <c-auto-complete
                      v-if="option.url"
                      display="chip"
                      v-model="option.value"
                      chips
                      :option="option"
                      :force-selection="true"
                    />
                    <p-multi-select
                      v-else-if="option.items && option.items.length != 0"
                      v-model="option.value"
                      :options="option.items"
                      :optionLabel="option.key"
                      :placeholder="option.label"
                      display="chip"
                    />
                    <p-input-text
                      v-else
                      v-model="option.value"
                      :placeholder="option.label"
                    />
                  </div>
                </template>
              </div>
            </div>
          </transition>
        </div>
        <template v-if="!hideTitle">
          <p-divider />
          <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
            <div class="my-1 flex-fill">
              <h4>Liste des enquêtes post-programme</h4>
            </div>
            <div v-if="$can('CREATE', 'ENQUETE_POSTE_PROGRAMME')" class="btn-group">
              <a
                @click.prevent="showEnquetePosteProgrammeDialog"
                href=""
                class="style_btn btn btn-warning"
                >Ajouter une enquête</a
              >
            </div>
            <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
              <PSplitButton
                label="Exporter"
                :model="exportActions"
                class="p-button-outlined p-button-primary"
              />
            </div>
            <div class="btn-group ms-4">
              <button
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                v-tooltip.top="'Affichage mode avatar'"
                @click.prevent="viewMode = 'card'"
                class="btn style_btn btn-md"
                :class="{
                  'btn-success': viewMode == 'card',
                  'btn-light': viewMode == 'table',
                }"
              >
                <i class="bi-grid" />
              </button>
              <button
                @click.prevent="viewMode = 'table'"
                v-tooltip.top="'Affichage mode tableau'"
                class="btn style_btn"
                :class="{
                  'btn-light': viewMode == 'card',
                  'btn-success': viewMode == 'table',
                }"
              >
                <i class="bi-table" />
              </button>
            </div>
          </div>
        </template>
      </template>
      <template v-if="!mxLoading">
        <PDivide v-if="hideTitle" />
        <div class="bg-">
          <div class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="single"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :sort-by.sync="mxPagination.sortBy"
                :sort-desc.sync="mxPagination.sortDesc"
                responsive="sm"
                sort-icon-left
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>

                <template #row-details="row">
                  <div class="bg- pa-2">
                    <div
                      v-if="row.item.statutEnquete == 'CLOTURE'"
                      class="alert alert-warning"
                    >
                      Cette enquête a déjà été clôturée. Aucune modification des fiches
                      d'enquête ne sera prise en compte.
                    </div>
                    <PTabView
                      v-if="
                        row.item.statutEnquete != null &&
                        row.item.statutEnquete !== 'VIDE'
                      "
                      :activeIndex.sync="activeTab"
                    >
                      <PTabPanel header="Liste des Fiches Poste Programme">
                        <FichePosteProgrammeList
                          :isEnqueteCloture="row.item.statutEnquete == 'CLOTURE'"
                          :extraQueryArg="`enquetePosteProgramme.id=${row.item.id}`"
                        />
                      </PTabPanel>
                    </PTabView>

                    <template v-else>
                      <a
                        class="style_btn btn btn-primary"
                        @click.prevent="genererFicheEnquetes(row.item)"
                      >
                        <span>Générer les fiches d'enquête</span>
                      </a>
                    </template>
                  </div>
                </template>

                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <button
                      v-tooltip.top="'Voir les détails'"
                      class="btn btn-sm py-1 btn-outline-primary me-1"
                      @click="mxToggleDetails(row, tableData)"
                    >
                      <i class="bi bi-journal-check"></i>
                    </button>

                    <a
                      v-if="$can('CREATE', 'ENQUETE_POSTE_PROGRAMME')"
                      @click.stop.prevent="modifierEnquete(row.item)"
                      v-tooltip.top="`Modifier l'enquête`"
                      class="btn btn-outline-success rounded btn-sm me-1"
                      ><i class="bi bi-pencil"></i
                    ></a>

                    <a
                      v-if="$can('CLOTURER_ENQUETE', 'ENQUETE_POSTE_PROGRAMME')"
                      @click.stop.prevent="cloturerEnquete(row.item)"
                      v-tooltip.top="`Clôturer l'enquête`"
                      class="btn btn-outline-danger rounded btn-sm me-1"
                      ><i class="bi bi-archive"></i
                    ></a>
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
                <template #cell(statutEnquete)="data">
                  <PTag :severity="getStatutEnqueteColor(data.item.statutEnquete)">{{
                    data.item.statutEnquete
                  }}</PTag>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{
                  (mxPagination.page <= 0 ? 0 : mxPagination.page - 1) *
                  mxPagination.rowsPerPage
                }}
                à
                {{
                  (mxPagination.page <= 0 ? 1 : mxPagination.page) *
                  mxPagination.rowsPerPage
                }}
                de {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import EnquetePosteProgrammeFormDialog from "./dialog/EnquetePosteProgrammeFormDialog.vue";
// import EnquetePosteProgrammeClotureDialog from "./dialog/EnquetePosteProgrammeClotureDialog.vue";
import FichePosteProgrammeList from "./FichePosteProgrammeList.vue";

export default {
  props: {
    agentId: {
      type: Number,
      default: () => null,
    },
    extraQueryArg: {
      type: String,
      default: "",
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    perideMode: {
      type: Boolean,
      default: false,
    },
  },
  components: { EnquetePosteProgrammeFormDialog, FichePosteProgrammeList },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      dateDeb: null,
      dateFin: null,
      activeTab: 0,
      showAdvancedFilter: false,
      viewMode: false,
      filterOptions: [],
      exportActions: [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            this.mxExportToPDF({
              meta: {
                title: "Liste des enquêtes poste programme",
                subtitle: "Liste des enquêtes poste programme",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "enquetePosteProgrammes/exportToPdf",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des enquêtes poste programme.xlsx",
                },
              },
            });
          },
        },
        {
          label: "Excel",
          icon: "pi pi-file-excel",
          command: () => {
            let params = this.mxGetQueryBuilder();
            let route = this.$router.resolve({
              name: "common.export",
              query: {
                fetcherMethod: "enquetePosteProgramme/exportToExcel",
                queryParams: params,
                fileName: "Liste des bénéficiaires Azoli.xlsx",
              },
            });
            window.open(route.href, "_blank");
          },
        },
      ],
      searchFields: ["title"],
      searchFieldsOptions: [
        // {
        //   label: "Bénéficiaire",
        //   value: "nomPrenom",
        //   visible: this.$can("filter.beneficiaire", "Azoli"),
        // },
        {
          label: "Title",
          value: "title",
          visible: true,
        },
      ],
      activeDemandeur: {},
    };
  },
  created() {
    this.queryStatistics();
  },
  watch: {
    dossier(val) {
      if (val.azoli) {
        this.activeDemandeur = {
          ...val,
          ...val.azoli, // écrase id du demandeur par id azoli
          id: val.id, //remettre Id du demandeur
        };
      }
    },
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
    extraQueryArg() {
      this.queryStatistics();
    },
  },
  computed: {
    ...mapGetters({
      pagination: "enquetePosteProgramme/pagination",
    }),
    statisticsQueryParams() {
      return this.mxBuildQueryParams();
    },
    tableFields() {
      let fields = [
        "#",
        // 'index',
        { key: "libelle", label: "Titre" },
        { key: "description", label: "Description" },
        { key: "dateDebut", label: "Date Début" },
        { key: "dateFin", label: "Date Fin" },
        { key: "statutEnquete", label: "Statut" },
        { key: "actions", label: "Actions" },
      ];
      return fields;
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
  },
  methods: {
    ...mapActions({
      cloturerFichesEnquetePosteProgramme:
        "enquetePosteProgramme/cloturerFichesEnquetePosteProgramme",
      fetchEnquetePosteProgrammes: "enquetePosteProgramme/fetchEnquetePosteProgrammes",
      genererFichesEnquetePosteProgramme:
        "enquetePosteProgramme/genererFichesEnquetePosteProgramme",
      createOrUpdateEnquetePosteProgramme:
        "enquetePosteProgramme/createOrUpdateEnquetePosteProgramme",
    }),
    async cloturerEnquete(item) {
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment clôtuer l'enquête ${item.tile}?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.cloturerFichesEnquetePosteProgramme({
            enquetePosteProgrammeId: item.id,
          }).then((data) => {
            item = item.statutEnquete = "CLOTUREE";

            this.$toast.success("Enquête clôturée avec succès!", {
              position: "top-right",
              duration: 10000,
            });
          });
        },
      });
    },
    getStatutEnqueteColor(status) {
      let color = "danger";
      if (status == "VIDE") {
        color = "warning";
      } else if (status == "GENERE") {
        color = "info";
      } else if (status == "EN_COURS") {
        color = "primary";
      } else if (status == "CLOTURE") {
        color = "danger";
      }
      return color;
    },
    genererFicheEnquetes(enquete) {
      this.genererFichesEnquetePosteProgramme({
        enquetePosteProgrammeId: enquete.id,
      });
    },
    submitQuery() {
      this.mxSubmitQuery();
    },
    showEnquetePosteProgrammeDialog() {
      this.$refs.enqueteDialog.show();
    },
    modifierEnquete(enquete) {
      this.$refs.enqueteDialog.setEditableItem(enquete);
      this.showEnquetePosteProgrammeDialog();
    },
    handleEnqueteSave(enquete) {
      this.createOrUpdateEnquetePosteProgramme(enquete);
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "enquetePosteProgramme",
        search: this.search,
        fetcherMethod: "enquetePosteProgramme/fetchEnquetePosteProgrammes",
        dataGetter: "enquetePosteProgramme/enquetePosteProgrammes",
        paginationGetter: "enquetePosteProgramme/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 8,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          return ``;
        },
        searchFields: this.searchFields,
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    editDemandeur(demandeur) {
      console.log(demandeur);
      this.activeDemandeur = { ...demandeur };
      this.$router.push({ name: "espace.ce.de.edit" });
    },
    showDemandeur(demandeur) {
      this.activeDemandeur = { ...demandeur };
      this.$refs.detailViewer.show();
    },
    async destroyDemandeur(demandeur) {
      await this.$confirm.require({
        group: "confirmDialog",
        message: "Voulez-vous vraiment supprimer cet élément?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.deleteDemandeur(demandeur);
        },
      });
    },
    updateOrCreateDemandeur(demandeur) {
      this.createOrUpdateDemandeur(demandeur).then((data) => {
        console.log(data);
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 5000,
        });
      });
    },
  },
};
</script>

<style></style>
